<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref, onMounted, computed } from 'vue'
import { TemperIcon, Flag } from '@temperworks/icons'
import {
  Typography,
  InputField,
  Checkbox
} from '@temperworks/components'

import {
  DropdownVariant,
  TypographyType,
  InputVariant,
  InputType,
  CheckBoxVariant,
  DropdownItemsInterface,
  IconPosition
} from '@temperworks/types'

interface Props {
  variant: DropdownVariant
  items: DropdownItemsInterface[]
  open: boolean
  selected?: string
  placeholderSearch?: string
  selectAllOption?: boolean
  multiSelect?:boolean
  withinBounds?: boolean
}

const { t } = useI18n()
const DropdownItems = ref<Array<DropdownItemsInterface>>([])
const props = defineProps<Props>()
const emit = defineEmits(['selected', 'multiSelected'])
const searchItem = ref<string>('')
const dropdownElement = ref<Element | null>(null)
const selectedItems = ref<Array<DropdownItemsInterface>>([])

function selectItem(item) {
  if (props.multiSelect) {
    multi(item)
  } else {
    emit('selected', item)
  }
}

onMounted(() => {
  DropdownItems.value = props.items
})

function search(item) {
  searchItem.value = item
}

function searchItems() {
  if (DropdownItems.value) {
    return DropdownItems.value
      .filter(a => {
        return a.name.toLowerCase().includes(searchItem.value.toLowerCase())
      })
  }
}

function selectAll() {
  if (selectedItems.value.length) {
    selectedItems.value = []
  } else {
    selectedItems.value = selectedItems.value.concat(DropdownItems)
  }
  emit('selected', selectedItems)
}

function multi(item) {
  const found = selectedItems.value.some(el => el.id === item.id)
  if (!found) {
    selectedItems.value.push(item)
  } else {
    const x = selectedItems.value.findIndex(el => el.id === item.id)
    selectedItems.value.splice(x, 1)
  }
  emit('multiSelected', selectedItems.value)
}

const getDropdownMaxHeight = computed(() => {
  const dropdownBoundingRect = dropdownElement.value?.getBoundingClientRect()
  return window.innerHeight - dropdownBoundingRect?.top - 30
})

const dropdownMaxHeight = computed(() => {
  return {
    maxHeight: getDropdownMaxHeight.value ? `${getDropdownMaxHeight.value}px` : 'unset',
  }
})

onMounted(() => {
  if (props.multiSelect) {
    const selectedTexts = props.selected.split(', ')
    selectedItems.value = props.items.filter((item) => {
      return selectedTexts.includes(item.name)
    })
  }
})
</script>

<template>
  <div
    ref="dropdownElement"
    v-if="props.open"
    class="dropdown-content"
    :style="withinBounds && dropdownMaxHeight"
  >
    <InputField
      v-if="variant.toLowerCase().includes('search')"
      :variant="InputVariant.withIconSmall"
      :type="InputType.search"
      :placeholder="placeholderSearch || t('search.placeholder')"
      @input="search($event)"
    />
    <div
      v-if="selectAllOption"
      class="select-all"
    >
      <Typography
        :variant="TypographyType.footnote"
        :content="selectedItems.length ? t('controls.clearAll') : t('controls.selectAll')"
        @clicked="selectAll()"
      />
    </div>
    <slot name="extra-content" />
    <div v-if="searchItems().length">
      <div
        v-for="(item, index) in searchItems()"
        :key="index"
        class="item"
        :class="
          item.type === 'heading' ? 'no-hover' : ''
        "
        @click="selectItem(item)"
      >
        <span
          v-if="item.icon || item.flag"
          class="item-icon"
        >
          <TemperIcon
            v-if="item.icon"
            :name="item.icon"
            :position="IconPosition.left"
            size="standard"
          />
          <Flag
            v-if="item.flag"
            :name="item.flag"
          />
        </span>
        <div :class="[{'inner-selecting': item.extra }, {'inner-flex': item.extraValue}]">
          <Checkbox
            v-if="variant.toLowerCase().includes('select')"
            :id="item.name"
            :variant="CheckBoxVariant.default"
            :copy="item.name"
            :active="selectedItems.includes(item)"
          />
          <Typography
            v-else
            :content="item.name"
            :variant="
              item.type === 'heading' ?
                TypographyType.caption :
                TypographyType.bodySmall
            "
            :class="{ 'grey800': item.type === 'heading' }"
          />
          <Typography
            v-if="item.extra"
            :content="item.extra"
            :variant="TypographyType.footnote"
            class="darkgrey extra-content"
          />
          <Typography
            v-if="item.extraValue"
            :content="item.extraValue"
            :variant="TypographyType.bodySmall"
            class="lightgrey"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="variant.toLowerCase().includes('search')"
      class="item no-hover"
    >
      <Typography
        :variant="TypographyType.bodySmall"
        :content="t('search.noResults')"
        class="grey600 block no-hover"
      />
    </div>
    <slot name="footer-content" />
  </div>
</template>

<style lang="scss" scoped>
@use './DropdownContent.scss';
</style>
