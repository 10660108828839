export enum DropdownVariant {
  default = 'default',
  withSearch = 'with-search',
  withSelect = 'with-select',
  withSelectSearch = 'with-select with-search',
  tableFilter = 'table-filter',
}

export enum DropdownContentItem {
  heading = 'heading',
  default = '',
}
