<script setup lang="ts">
import { 
  nl,
  uk,
  fr
} from './Flags/index'
import { FlagNames, IconPosition } from '@temperworks/types'
interface Props {
  name: FlagNames
  position?: IconPosition
}
const props = defineProps<Props>()

const flags : { [key: string]: any } = {
  uk,
  nl,
  fr
}
</script>

<template>
  <component 
    :is="flags[props.name] || 'nl'"
    :position="props.position || 'right'"
  />
</template>
