<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { Typography } from '@temperworks/components'
import { CheckBoxVariant, TypographyType } from '@temperworks/types'

interface Props {
  variant: CheckBoxVariant
  id: string | object | number
  copy?: string
  disabled?: boolean
  required?: boolean
  active?: boolean
  error?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  id: undefined,
  copy: undefined
})
const emit = defineEmits(['checked'])
const checked = ref<boolean>(false)

onMounted(() => {
  if (props.active) {
    checked.value = props.active
  }
})

watch(() => props.active, (first, second) => {
  if (first) {
    checked.value = true
  }
  if (second) {
    checked.value = false
  }
})

function onChange(number:any) {
  if (!props.disabled) {
    checked.value = !checked.value
    emit('checked', { number, checked })
  }
}
</script>

<template>
  <div
    class="checkbox-item"
    :class="{
      'disabled' : disabled,
      'checkbox-item--small': props.variant === CheckBoxVariant.small
    }"
  >
    <input
      :id="props.id.toString()"
      ref="checkbox"
      type="checkbox"
      :aria-checked="props.active"
      :class="
        [
          props.variant,
          { 'disabled': props.disabled },
          { 'errored': props.error },
        ]
      "
      :checked="checked"
      :aria-required="props.required"
      @click="onChange(props.id)"
    >
    <span
      v-if="props.copy"
      class="checkbox-copy"
      @click="onChange(props.id)"
    >
      <Typography
        :variant="props.variant === CheckBoxVariant.small
          ? TypographyType.bodySmall
          : TypographyType.body
        "
        :content="props.copy"
      />
    </span>
  </div>
</template>

<style lang="scss" scoped>
@use './CheckBox.scss';
</style>
